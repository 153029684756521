
@import "~react-toastify/dist/ReactToastify.min.css";

// $coreui-icons-font-path: "~@coreui/icons/fonts";
// @import "~@coreui/icons/scss/free.scss";

// $body-bg: #fff;
// $body-color: #000;
$theme-colors: (
  // "primary": #1d1d1d,
  "primary": #0073ff,
  // "secondary": #daa04c,
//   "dark": #000,
//   "danger": #ff4136
);


// $gray-600: #212121;
// $gray-800: #000;
// $theme-colors: (
//   // "blue": $blue,
//   // "red": $red,
//   // "orange": $orange,
//   // "yellow": $yellow,
//   // "green": $green,
//   // "white": $white,
//   "gray": $gray-600,
//   "gray-dark": $gray-800,
//   "dark": #000
// ) !default;
@import "~@coreui/coreui/scss/coreui";

.page-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem 0 0 0;

  &.dark {
    background-color: $dark;
  }
}

.table thead th {
  border-top: none;
}

.react-datepicker-wrapper {
  display: block !important;
}

// Override because of react-select height
.form-control {
  height: calc(1.5em + 0.75rem + 5px);

  &.border-error {
    border-color: rgb(229, 83, 83);
  }

  color: $gray-base;

  &:focus {
    color: $gray-base;
  }
}
